<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <accion-correctora-form
          v-if="anomalia && anomalia.tanomalia_checklist_ot"
          :id="routeParams.idaccion_correctora_ot"
          :idtanomalia-checklist-ot="anomalia.tanomalia_checklist_ot.idtanomalia_checklist_ot"
          :material-afectado="materialAfectado"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import AccionCorrectoraForm from '../components/AccionCorrectoraForm'
import Data from './AccionCorrectoraEditData'

export default {
  components: {
    AccionCorrectoraForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      anomalia: {},
      materialAfectado: {},
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar acción correctora'
      this.anomalia = await this.$offline.tanomaliaChecklistOt.row(this.routeParams.idtanomalia_checklist_ot)
      this.materialAfectado = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(this.routeParams.idpregunta_checklist_ot)
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(this, this.routeParams.idaccion_correctora_ot, this.formData)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.accionCorrectoraOt, this.routeParams.idaccion_correctora_ot)
      this.$appRouter.go(-1)
    },
  },
}
</script>
