export default {
  async update (Vue, id, formData) {
    await Vue.$offline.accionCorrectoraOt.updateSync({
      idaccion_correctora_ot: id,
      idaccion_correctora: formData.accion_correctora?.accion_correctora?.idaccion_correctora,
      descripcion: formData.descripcion,
      descripcion_ampliada: formData.descripcion_ampliada,
      realizar: formData.realizar,
      observaciones: formData.observaciones,
      idarticulo: formData.idarticulo,
      idmaccion: formData.idmaccion,
      unidades: formData.unidades,
    })
  },
}
